import React from 'react';
import { DemoAppsItems } from '../utils/DemoApps';
import ReferencePage from '../components/common/ReferencePage';
import { readFileSync } from 'fs';
import { replaceRoutes } from '../utils/mapRoutes';

export function getServerData() {
  let type = 'tsx';

  const sideNav = readFileSync(`./src/utils/sideNavItems.json`, 'utf8');
  const sideNavItem = JSON.parse(sideNav);

  const route = readFileSync(`./src/docs-config/src/constants/routes.json`, 'utf8');
  const routes = JSON.parse(route);

  const sideNavItems = replaceRoutes(sideNavItem, routes);
    
  return {
    props: {
    clientSideNavList: sideNavItems || [],
    type: type,
    }
  };
}

const DemoAppsPage: React.FC = () => {
  return (
    <ReferencePage
      title="Demos and Sample Apps"
      description="Check out the following demos and sample apps to learn how Skyflow can protect your sensitive data."
      items={DemoAppsItems}
      isRouteCard={true}
    ></ReferencePage>
  );
};

export default DemoAppsPage;
